<template>
  <validation-observer v-slot="{invalid}">
    <b-modal
        v-model="addPlantPrompt"
        :number="true"
        :ok-disabled="invalid"
        cancel-title="Annuler"
        cancel-variant="outline-danger"
        centered
        ok-title="Ajouter"
        ok-variant="primary"
        :title="addingPlant.common_name"
        @ok="addPlant">
      <b-col cols="12">
        <b-form-group class="text-center">
          <label class="font-weight-bold font-medium-1 mb-50">Type d'ajout</label>
          <b-form-radio-group v-model="addPlantType">
            <b-form-radio name="addPlantType" value="S">Plants individuels</b-form-radio>
            <b-form-radio name="addPlantType" value="A">Rang ou zone</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>

      <b-col cols="12" v-if="addPlantType==='S'">
        <b-form-group class="text-center">
          <label class="font-weight-bold font-medium-1 mb-50">Nombre de plants</label>
          <validation-provider v-slot="{errors}" :rules="{min_value:1, max_value:10, integer:null}">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <b-form-input
                  v-model="addPlantNumber"
                  max="10"
                  min="1"
                  step="1"
                  style="width: 150px;"
                  type="number"/>
              <b-form-text class="text-danger font-small-3 text-center">{{ errors[0] }}</b-form-text>
            </div>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12" v-if="addPlantType==='A'">
        <b-form-group class="text-center">
          <label class="font-weight-bold font-medium-1 mb-50">
            Dimension horizontale <span class="font-weight-normal font-italic">(m)</span>
          </label>
          <validation-provider v-slot="{errors}" :rules="{max_size_value:vGarden.length,min_size_value:0}">
            <div class="d-flex justify-content-center align-items-center">
              <b-input-group>
                <b-input-group-prepend is-text>
                  <span class="text-left font-weight-bold">Longueur</span>
                </b-input-group-prepend>
                <b-form-input
                    v-model="addPlantAreaLength"
                    :max="vGarden.length"
                    :min="0"
                    :step="addPlantAreaSpacingX"
                    type="number"/>
              </b-input-group>
            </div>
            <b-form-text class="text-danger pb-50">{{ errors[0] }}</b-form-text>
          </validation-provider>

          <validation-provider v-slot="{errors}" :rules="{max_size_value:vGarden.width,min_size_value: 0}">
            <div class="d-flex justify-content-center align-items-center">
              <b-input-group >
                <b-input-group-prepend is-text>
                  <span class="text-left font-italic">Espacement</span>
                </b-input-group-prepend>
                <b-form-input
                    v-model="addPlantAreaSpacingX"
                    :max="vGarden.width"
                    :min="0.05"
                    :step="0.05"
                    type="number"/>
              </b-input-group>
            </div>
            <b-form-text class="text-danger pb-2">{{ errors[0] }}</b-form-text>
          </validation-provider>


          <label class="font-weight-bold font-medium-1 mb-50">
            Dimension verticale <span class="font-weight-normal font-italic">(m)</span>
          </label>
          <validation-provider v-slot="{errors}" :rules="{max_size_value:vGarden.width, min_size_value:0}">
            <div class="d-flex justify-content-center align-items-center">
              <b-input-group>
                <b-input-group-prepend is-text>
                  <span class="text-left font-weight-bold">Largeur</span>
                </b-input-group-prepend>
                <b-form-input
                    v-model="addPlantAreaWidth"
                    :max="vGarden.width"
                    :min="0"
                    :step="addPlantAreaSpacingY"
                    type="number"/>
              </b-input-group>
            </div>
            <b-form-text class="text-danger pb-50">{{ errors[0] }}</b-form-text>
          </validation-provider>

          <validation-provider v-slot="{errors}" :rules="{max_size_value:vGarden.width,min_size_value:0}">
            <div class="d-flex justify-content-center align-items-center">
              <b-input-group >
                <b-input-group-prepend is-text>
                  <span class="text-left font-italic"> Espacement </span>
                </b-input-group-prepend>
                <b-form-input
                    v-model="addPlantAreaSpacingY"
                    :max="vGarden.width"
                    :min="0.05"
                    :step="0.05"
                    type="number"/>
              </b-input-group>
            </div>
            <b-form-text class="text-danger pb-2">{{ errors[0] }}</b-form-text>
          </validation-provider>

          <b-form-text class="text-info pb-50">
            Espacement minimal recommandé : {{100 * plantDiameter}} centimètres
          </b-form-text>

          <b-form-text class="text-info pb-50">
            <strong>{{(Math.floor(addPlantAreaLength/addPlantAreaSpacingX)+1) *
            (Math.floor(addPlantAreaWidth/addPlantAreaSpacingY)+1)}}
              plants </strong> au total (<strong>{{Math.floor(addPlantAreaLength/addPlantAreaSpacingX)+1}}</strong> horizontal et
            <strong>{{Math.floor(addPlantAreaWidth/addPlantAreaSpacingY)+1}}
            </strong> vertical)
          </b-form-text>
        </b-form-group>
      </b-col>
    </b-modal>
  </validation-observer>
</template>

<script>
import {BModal, BCol, BFormGroup, BFormRadioGroup, BFormRadio, BFormInput, BFormText, BInputGroup, BInputGroupPrepend } from "bootstrap-vue";
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {integer, max_value, min_value} from 'vee-validate/dist/rules'

extend('min_value', {...min_value, message: "Vous ne pouvez pas ajouter moins de {min} plant"});
extend('max_value', {...max_value, message: "Vous ne pouvez pas ajouter plus de  {max} plants"});
extend('integer', {...integer, message: "Le champ doit être un nombre entier!"});
extend('max_size_value', {...max_value, message: 'Valeur maximale: {max}'});
extend('min_size_value', {...min_value, message: 'Valeur minimale: {min}'});

export default {
  name: "VGPlantAdd",
  props: {
    addingPlant: {
      type: Object,
      default: null
    },
    vGarden:  {
      type: Object,
      default: null
    },
  },
  components: {
    BModal,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    BFormInput,
    BFormText,
    BInputGroup,
    BInputGroupPrepend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      addPlantNumber: 1,
      addPlantType: 'S',
      addPlantAreaLength: 0,
      addPlantAreaWidth: 0,
      addPlantAreaSpacingX: this.plantDiameter,
      addPlantAreaSpacingY: this.plantDiameter,
      addPlantPrompt: false,
    }
  },
  computed: {
    plantDiameter() {
      return this.addingPlant.jaya_infos ? 2 * this.addingPlant.jaya_infos.planting_radius : 0
    }
  },
  methods: {
    async addPlant() {
      let payload = {
        plant: this.addingPlant.id,
        vegetable_garden: this.vGarden.id,
        x: this.vGarden.length/2,
        y: this.vGarden.width/2,
      }
      if (this.addPlantType === 'S') {
        await Promise.all(
            Array(Number(this.addPlantNumber <= 10 ? this.addPlantNumber : 10)).fill("nonNull")
                .map(async () => {
                   await this.$store.dispatch("garden/addVGardenPlant", payload)
                })
        ).then(() => {
          this.$emit('plant-added')
          this.$notify({
            time: 4000,
            color: "primary",
            title: "Plante(s) ajoutée(s)",
            text: "La plante a bien été ajoutée à votre potager"
          })
        })
      }
      if (this.addPlantType === 'A') {
        payload.pattern = {
          type : "rectangle",
          size_x: Number(this.addPlantAreaLength),
          spacing_x: Number(this.addPlantAreaSpacingX),
          size_y: Number(this.addPlantAreaWidth),
          spacing_y: Number(this.addPlantAreaSpacingY)
        }
        this.$store.dispatch("garden/addVGardenPlant", payload)
            .then(() => {
              this.$emit('plant-added')
              this.$notify({
                time: 4000,
                color: "primary",
                title: "Plante(s) ajoutée(s)",
                text: "La plante a bien été ajoutée à votre potager"
              })
            })
      }
    },
  },
  watch: {
    addingPlant() {
      this.addPlantNumber = 1
      this.addPlantType = 'S'
      this.addPlantAreaLength = 0
      this.addPlantAreaWidth = 0
      this.addPlantAreaSpacingX = this.plantDiameter
      this.addPlantAreaSpacingY = this.plantDiameter
    }
  }
}
</script>
