var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-modal',{attrs:{"number":true,"ok-disabled":invalid,"cancel-title":"Annuler","cancel-variant":"outline-danger","centered":"","ok-title":"Ajouter","ok-variant":"primary","title":_vm.addingPlant.common_name},on:{"ok":_vm.addPlant},model:{value:(_vm.addPlantPrompt),callback:function ($$v) {_vm.addPlantPrompt=$$v},expression:"addPlantPrompt"}},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"text-center"},[_c('label',{staticClass:"font-weight-bold font-medium-1 mb-50"},[_vm._v("Type d'ajout")]),_c('b-form-radio-group',{model:{value:(_vm.addPlantType),callback:function ($$v) {_vm.addPlantType=$$v},expression:"addPlantType"}},[_c('b-form-radio',{attrs:{"name":"addPlantType","value":"S"}},[_vm._v("Plants individuels")]),_c('b-form-radio',{attrs:{"name":"addPlantType","value":"A"}},[_vm._v("Rang ou zone")])],1)],1)],1),(_vm.addPlantType==='S')?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"text-center"},[_c('label',{staticClass:"font-weight-bold font-medium-1 mb-50"},[_vm._v("Nombre de plants")]),_c('validation-provider',{attrs:{"rules":{min_value:1, max_value:10, integer:null}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center"},[_c('b-form-input',{staticStyle:{"width":"150px"},attrs:{"max":"10","min":"1","step":"1","type":"number"},model:{value:(_vm.addPlantNumber),callback:function ($$v) {_vm.addPlantNumber=$$v},expression:"addPlantNumber"}}),_c('b-form-text',{staticClass:"text-danger font-small-3 text-center"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1):_vm._e(),(_vm.addPlantType==='A')?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"text-center"},[_c('label',{staticClass:"font-weight-bold font-medium-1 mb-50"},[_vm._v(" Dimension horizontale "),_c('span',{staticClass:"font-weight-normal font-italic"},[_vm._v("(m)")])]),_c('validation-provider',{attrs:{"rules":{max_size_value:_vm.vGarden.length,min_size_value:0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('span',{staticClass:"text-left font-weight-bold"},[_vm._v("Longueur")])]),_c('b-form-input',{attrs:{"max":_vm.vGarden.length,"min":0,"step":_vm.addPlantAreaSpacingX,"type":"number"},model:{value:(_vm.addPlantAreaLength),callback:function ($$v) {_vm.addPlantAreaLength=$$v},expression:"addPlantAreaLength"}})],1)],1),_c('b-form-text',{staticClass:"text-danger pb-50"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":{max_size_value:_vm.vGarden.width,min_size_value: 0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('span',{staticClass:"text-left font-italic"},[_vm._v("Espacement")])]),_c('b-form-input',{attrs:{"max":_vm.vGarden.width,"min":0.05,"step":0.05,"type":"number"},model:{value:(_vm.addPlantAreaSpacingX),callback:function ($$v) {_vm.addPlantAreaSpacingX=$$v},expression:"addPlantAreaSpacingX"}})],1)],1),_c('b-form-text',{staticClass:"text-danger pb-2"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('label',{staticClass:"font-weight-bold font-medium-1 mb-50"},[_vm._v(" Dimension verticale "),_c('span',{staticClass:"font-weight-normal font-italic"},[_vm._v("(m)")])]),_c('validation-provider',{attrs:{"rules":{max_size_value:_vm.vGarden.width, min_size_value:0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('span',{staticClass:"text-left font-weight-bold"},[_vm._v("Largeur")])]),_c('b-form-input',{attrs:{"max":_vm.vGarden.width,"min":0,"step":_vm.addPlantAreaSpacingY,"type":"number"},model:{value:(_vm.addPlantAreaWidth),callback:function ($$v) {_vm.addPlantAreaWidth=$$v},expression:"addPlantAreaWidth"}})],1)],1),_c('b-form-text',{staticClass:"text-danger pb-50"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":{max_size_value:_vm.vGarden.width,min_size_value:0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('span',{staticClass:"text-left font-italic"},[_vm._v(" Espacement ")])]),_c('b-form-input',{attrs:{"max":_vm.vGarden.width,"min":0.05,"step":0.05,"type":"number"},model:{value:(_vm.addPlantAreaSpacingY),callback:function ($$v) {_vm.addPlantAreaSpacingY=$$v},expression:"addPlantAreaSpacingY"}})],1)],1),_c('b-form-text',{staticClass:"text-danger pb-2"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('b-form-text',{staticClass:"text-info pb-50"},[_vm._v(" Espacement minimal recommandé : "+_vm._s(100 * _vm.plantDiameter)+" centimètres ")]),_c('b-form-text',{staticClass:"text-info pb-50"},[_c('strong',[_vm._v(_vm._s((Math.floor(_vm.addPlantAreaLength/_vm.addPlantAreaSpacingX)+1) * (Math.floor(_vm.addPlantAreaWidth/_vm.addPlantAreaSpacingY)+1))+" plants ")]),_vm._v(" au total ("),_c('strong',[_vm._v(_vm._s(Math.floor(_vm.addPlantAreaLength/_vm.addPlantAreaSpacingX)+1))]),_vm._v(" horizontal et "),_c('strong',[_vm._v(_vm._s(Math.floor(_vm.addPlantAreaWidth/_vm.addPlantAreaSpacingY)+1)+" ")]),_vm._v(" vertical) ")])],1)],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }