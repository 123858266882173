<template>
  <div class="garden-info max-w-lg px-1">
    <TextBlock title="Informations">
      <b-button
          slot="titleSpace"
          class="p-0"
          pill
          variant="flat-info"
          @click="infoPopUp = true">
        <feather-icon
            icon="InfoIcon"
            size="24"/>
      </b-button>
    </TextBlock>

    <b-form-group label="Nom de la planche potagère">
      <validation-provider v-slot="{errors}" immediate rules="required">
        <b-form-input
            id="vGardenNameInput"
            v-model="name"
            placeholder="Nom de la planche potagère"/>
        <b-form-text class="text-danger">{{ errors[0] }}</b-form-text>
      </validation-provider>
    </b-form-group>

    <b-form-group label="Jardin asocié :">
      <b-input-group>
        <b-form-select
            v-if="initialSetUp"
            v-model="associatedGarden"
            :options="userGardens"
            placeholder="Sélectionnez un jardin associé"/>
        <div v-else-if="vgarden && vgarden.garden">
          {{this.$store.state.garden.gardens.find((garden) => garden.id === vgarden.garden).name}}
        </div>
      </b-input-group>
    </b-form-group>

    <b-form-group label="Dimensions de la planche potagère">
      <validation-provider v-slot="{errors}" :rules="{max_size_value:20,min_size_value:0.1}">
        <b-input-group style="width: 250px; !important">
          <b-form-input
              v-model="length"
              max="20"
              min="0.1"
              step="0.1"
              type="number"/>
          <b-input-group-append is-text>
            <span class="text-left" style="width:100px !important;"> Longueur (m) </span>
          </b-input-group-append>
        </b-input-group>
        <b-form-text class="text-danger pb-50">{{ errors[0] }}</b-form-text>
      </validation-provider>

      <validation-provider v-slot="{errors}" :rules="{max_size_value:length, min_size_value:0.1}">
        <b-input-group style="width: 250px; !important">
          <b-form-input
              v-model="width"
              :max="length"
              min="0.1"
              step="0.1"
              type="number"/>
          <b-input-group-append is-text>
            <span class="text-left" style="width:100px !important;"> Largeur (m) </span>
          </b-input-group-append>
        </b-input-group>
        <b-form-text class="text-danger pb-50">{{ errors[0] }}</b-form-text>
      </validation-provider>
    </b-form-group>

    <b-card :title="initialSetUp ? 'Informations optionnelles' :  'Informations supplémentaires'">
      <h5>Situation</h5>
      <b-form-radio-group v-model="situation" class="mb-1">
        <b-form-radio name="situation-radios" value="outside">Exterieur</b-form-radio>
        <b-form-radio name="situation-radios" value="inside">En serre</b-form-radio>
      </b-form-radio-group>

      <JayaTooltip class="mt-1" title="Ensoleillement">
        <h5>
          Ensoleillement
          <feather-icon
              icon="InfoIcon"
              class="text-info"
              size="16"/>
        </h5>
        <div slot="text">
          <p><strong>Soleil</strong> Du soleil toute la journée;</p>
          <p><strong>Mi-ombre</strong> Du soleil au moins la moitié de la journée;</p>
          <p><strong>Ombre</strong> Le potager est à l'ombre quasiment toute la journée</p>
        </div>
      </JayaTooltip>

      <b-form-radio-group v-model="exposition">
        <b-form-radio name="exposition" value="SU">Soleil</b-form-radio>
        <b-form-radio name="exposition" value="MS">Mi-ombre</b-form-radio>
        <b-form-radio name="exposition" value="SH">Ombre</b-form-radio>
      </b-form-radio-group>

      <div class="mt-1">
        <div class="d-flex cursor-pointer">
          <h5 class="pr-1" @click="$refs.soilInfoPopUp.openPopUp()">
            Type de sol
          </h5>
        </div>
        <!-- list using GEPPA 1963 texture diagram -->
        <label>Texture du sol</label>
        <b-form-select
            v-model="soil_type"
            :options="soilTypeOptions"/>

        <label class="mt-50">Richesse en matière organique</label>
        <b-form-select
            v-model="organic_richness"
            :options="organicRichnessOptions"/>

        <div class="d-flex justify-content-center mt-1">
          <b-button
              variant="outline-dark"
              @click="$refs.soilIdPopUp.openPopUp()">
            Qualifier la texture de son sol
          </b-button>
        </div>
      </div>
    </b-card>

    <div v-if="!initialSetUp" class="d-flex justify-content-around mt-2 mb-1">
      <b-button
          class="col-6"
          variant="danger"
          @click="deleteVGardenPrompt = true">
        Supprimer la planche potagère
      </b-button>
      <b-button
          class="col-5"
          variant="primary"
          @click="updateVGarden">
        Mettre à jour
      </b-button>
    </div>

    <b-modal
        ref="deleteVGardenPrompt"
        v-model="deleteVGardenPrompt"
        cancel-title="Annuler"
        cancel-variant="outline-gray"
        centered
        ok-title="Supprimer"
        ok-variant="danger"
        title="Supprimer cette planche potagère"
        @cancel="deleteVGardenPrompt = false"
        @ok="deleteVGarden">
      Etes vous sûr de vouloir supprimer cette planche potagère ? Une fois supprimée, elle ne pourra plus être récupérée.
    </b-modal>

    <b-modal
        v-model="infoPopUp"
        centered
        class="help-popup"
        hide-footer
        title="Les informations du jardin">
      <div class="text-justify"> Nous utilisons ces informations pour mieux vous conseiller sur le bien être de votre
        potager.
      </div>
      <b-list-group flush>
        <b-list-group-item class="font-small-3 text-justify">
          <strong>Jardin lié</strong><br>
          Le jardin nous permet d’avoir le code postal lié et donc le climat et la météo sur votre potager
        </b-list-group-item>
        <b-list-group-item class="font-small-3 text-justify">
          <strong>Dimensions de la planche potagère</strong><br>
          Si vous avez - ou planifiez - un grand potager (avec plus d'une centaine de plantes environ),
          nous vous conseillons de le séparer en plusieurs plusieurs petits potagers sur Hortilio.
        </b-list-group-item>
        <b-list-group-item class="font-small-3 text-justify">
          <strong>Type de potager</strong><br>
          Le type de potager nous permet de savoir s’il faut adapter la fréquence d’arrosage en fonction de la pluie ou
          non
        </b-list-group-item>
        <b-list-group-item class="font-small-3 text-justify">
          <strong>Ensoleillement</strong><br>
          L’ensoleillement lié au climat nous permet la prise en compte de la quantité de soleil et la chaleur dans
          votre potager
        </b-list-group-item>
        <b-list-group-item class="font-small-3 text-justify">
          <strong>Type de sol</strong><br>
          Le type de sol nous permet de savoir sa texture et sa richesse en matière organique
        </b-list-group-item>
      </b-list-group>
      <div class="text-justify">Toutes ces informations nous servent à vous conseiller les plantes adaptées a votre
        terrain.
        Elles nous permettront aussi de vous donner des conseils plus personnalisés tout au long de la vie de votre
        potager.
      </div>
    </b-modal>

    <JayaPopUp ref="soilInfoPopUp" name="FichePlante">
      <b-card>
        <SoilInfo noButtons/>
      </b-card>
    </JayaPopUp>

    <JayaPopUp ref="soilIdPopUp" name="FichePlante">
      <b-card>
        <SoilIdentification :initialSetUp="initialSetUp"/>
      </b-card>
    </JayaPopUp>
  </div>
</template>

<script>
import 'vue-simple-suggest/dist/styles.css'
import TextBlock from '@/components/global/JayaTextBlock.vue'
import {
  BButton,
  BCard,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormRadioGroup,
  BFormSelect,
  BFormText,
  BInputGroup,
  BInputGroupAppend,
  BListGroup,
  BListGroupItem,
  BModal,
} from "bootstrap-vue";
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import SoilInfo from '@/components/vgarden/evaluations/SoilInfo.vue'
import SoilIdentification from '@/components/vgarden/evaluations/SoilIdentification.vue'
import JayaPopUp from "@/components/global/JayaPopUp";
import {extend, ValidationProvider} from 'vee-validate'
import {max_value, min_value, required} from 'vee-validate/dist/rules'

extend('required', {...required, message: 'Ce champ est requis'});
extend('max_size_value', {...max_value, message: 'Valeur maximale: {max}'});
extend('min_size_value', {...min_value, message: 'Valeur minimale: {min}'});

export default {
  components: {
    BButton,
    BModal,
    BFormText,
    BListGroup,
    BListGroupItem,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormRadio,
    BFormRadioGroup,
    BCard,
    BCardActions,
    JayaPopUp,
    TextBlock,
    SoilInfo,
    SoilIdentification,
    ValidationProvider,
  },
  data() {
    return {
      vgarden: {},
      deleteVGardenPrompt: false,
      infoPopUp: false,
      name: '',
      situation: 'UN',
      exposition: 'UN',
      depth: 0,
      width: 0,
      length: 0,
      soil_type: null,
      soilTypeOptions: [
        {value: null, text: 'Texture du sol', disabled: true},
        {value: 'AA', text: 'Très fine - Argile lourde'},
        {value: 'A', text: 'Très fine - Argile'},
        {value: 'ALS', text: 'Fine - Argile limono-sableux'},
        {value: 'AL', text: 'Fine - Argile limonseuse'},
        {value: 'LAS', text: 'Fine - Limon argilo-sableux'},
        {value: 'LA', text: 'Fine - Limon argileux'},
        {value: 'AS', text: 'Fine - Argile sableuse'},
        {value: 'LSA', text: 'Moyenne - Limon sablo-argileux'},
        {value: 'L', text: 'Moyenne - Limon'},
        {value: 'LS', text: 'Moyenne - Limon sableux'},
        {value: 'SA', text: 'Moyenne - Sable argileux'},
        {value: 'SAL', text: 'Moyenne - Sable argileo-limoneux'},
        {value: 'SI', text: 'Grossière - Sable limoneux'},
        {value: 'S', text: 'Grossière - Sableux'},
        {value: 'SS', text: 'Grossière - Sable'},
        {value: 'UN', text: 'Je ne sais pas'},

      ],
      organic_richness: null,
      organicRichnessOptions: [
        {value: null, text: 'Richesse en matière organique', disabled: true},
        {value: 'VP', text: 'Très pauvre'},
        {value: 'PO', text: 'Pauvre'},
        {value: 'NO', text: 'Normale'},
        {value: 'RI', text: 'Riche'},
        {value: 'VR', text: 'Très riche'},
        {value: 'UN', text: 'Je ne sais pas'},
      ],
      associatedGarden: null,
    }
  },
  props: {
    initialSetUp: {
      type: Boolean,
      default: false
    },
  },
  created() {
    if (!this.initialSetUp) {
      let vgardenId = Number(this.$route.params.vgardenId)
      if (vgardenId >= 0) {
        this.vgarden = this.$store.state.garden.vgardens.find(vgarden => vgarden.id === vgardenId)
      }
      this.name = this.vgarden.name
      if (this.vgarden.outside_vgarden === true) {
        this.situation = 'outside'
      } else if (this.vgarden.outside_vgarden === false) {
        this.situation = 'inside'
      }
      this.exposition = this.vgarden.exposition
      this.width = this.vgarden.width
      this.length = this.vgarden.length
      this.soil_type = this.vgarden.soil_type
      this.organic_richness = this.vgarden.organic_richness
    }
  },
  mounted() {
    //get main garden
    for (const garden of this.$store.state.garden.gardens) {
      if (garden.is_active) {
        this.associatedGarden = garden
      }
    }
  },
  computed: {
    userGardens() {
      let gardensArray = [{value: null, text: 'Jardin associé', disabled: true}]
      for (const garden of this.$store.state.garden.gardens) {
        let localAuth = garden.local_authority ? ' ' + garden.local_authority_infos.name : ''
        gardensArray.push({value: garden, text: garden.name + localAuth})
      }
      return gardensArray
    },
  },
  methods: {
    updateVGarden() {
      let payload = {
        id: this.vgarden.id,
        garden: this.vgarden.garden,
        name: this.name,
        length: this.length,
        width: this.width,
        exposition: this.exposition,
        soil_type: this.soil_type,
        organic_richness: this.organic_richness
      }

      if (this.situation === 'inside') {
        payload['outside_vgarden'] = false
      } else if (this.situation === 'outside') {
        payload['outside_vgarden'] = true
      } else {
        payload['outside_vgarden'] = null
      }

      this.$store.dispatch('garden/updateVGarden', payload)
          .then(() => {
            this.$notify({
              color: "primary",
              text: "Planche potagère mise à jour",
              time: 4000
            })
            this.$emit('endUpdate')
          })
    },
    deleteVGarden() {
      this.$store.dispatch('garden/deleteVGarden', this.vgarden.id)
      this.$router.push('/')
    },
  },
  watch: {
    width() {
      if (Number(this.width) > Number(this.length)) {
        this.width = this.length
      }
      if (this.initialSetUp) {
        this.$emit('updateInfo')
      }
    },
    length() {
      if (Number(this.width) > Number(this.length)) {
        this.width = this.length
      }
      if (this.initialSetUp) {
        this.$emit('updateInfo')
      }
    },
    name() {
      if (this.initialSetUp) {
        this.$emit('updateInfo')
      }
    },
    garden() {
      if (this.initialSetUp) {
        this.$emit('updateInfo')
      }
    },
    outside_vgarden() {
      if (this.initialSetUp) {
        this.$emit('updateInfo')
      }
    },
    exposition() {
      if (this.initialSetUp) {
        this.$emit('updateInfo')
      }
    },
    soil_type() {
      if (this.initialSetUp) {
        this.$emit('updateInfo')
      }
    },
    organic_richness() {
      if (this.initialSetUp) {
        this.$emit('updateInfo')
      }
    }
  }
}
</script>
