<template>
  <b-row v-if="plantList.length > 0" ref="VGardenInteractions" class="interactions-view" no-gutters>
    <b-col class="d-flex justify-content-center" cols="12">
      <svg :key="svgKey" :height="pannelSize+5" :width="pannelSize">
        <template v-if="interactions.length > 0">
          <path
              v-for="(interaction, index) in interactions"
              :key="index + plantList.length"
              :d="interactionPath(selectedVgardenPlantId, interaction)"
              :style="ligneStyle(interaction.positive)"
              fill="none"
              stroke-width="2"/>
        </template>

        <template>
          <VGardenPlantObject
              v-for="(plant, index) in plantList"
              :key="index"
              :maxHeight="pannelSize"
              :maxWidth="pannelSize"
              :pictoName="getPictoName(plant)"
              :plantColor="plantColor(plant)"
              :plantIndex="index"
              :plantInteractions="plant.garden_plant.plant.received_interactions"
              :plantName="plant.garden_plant.plant.common_name"
              :plantRadius="plantRadius(plantList.length)"
              :plantSCName="plant.garden_plant.plant.scientific_name"
              :quantity="plant.quantity"
              :rotation="plantRotation(index, plantList.length)"
              :simplify="simplify"
              :textColor="textColor(plant)"
              :vGarden="vGarden"
              :xPosition="plantPosition(index, plantList.length, radius).x"
              :yPosition="plantPosition(index, plantList.length, radius).y"
              @plantClick="plantClick(plant)">
          </VGardenPlantObject>
        </template>
      </svg>
    </b-col>

    <b-col cols="12">
      <app-collapse>
        <app-collapse-item class="m-1 text-center" title="Légende">
          <b-list-group class="mb-1 text-left" flush>
            <b-list-group-item class="d-flex align-items-center">
              <svg height="20" width="45">
                <path d="M 0 10 l 30 0 " stroke='var(--success)' stroke-width="2"/>
              </svg>
              <h5>Interaction positive</h5>
            </b-list-group-item>
            <b-list-group-item class="d-flex align-items-center">
              <svg height="20" width="45">
                <path d="M 0 10 l 30 0 " stroke='var(--danger)' stroke-dasharray="10,2,2,2" stroke-width="2"/>
              </svg>
              <h5>Interaction négative</h5>
            </b-list-group-item>
            <b-list-group-item class="d-flex align-items-center">
              <div class="circle-legend mr-2 ml-50" style="background-color: var(--info-light)"/>
              <h5>Plante sélectionnée</h5>
            </b-list-group-item>
            <b-list-group-item class="d-flex align-items-center">
              <div class="circle-legend  mr-2 ml-50" style="background-color: var(--success-light)"/>
              <h5>Plante bénéfique pour la plante sélectionnée</h5>
            </b-list-group-item>
            <b-list-group-item class="d-flex align-items-center">
              <div class="circle-legend  mr-2 ml-50" style="background-color: var(--danger-light)"/>
              <h5>Plante néfaste pour la plante sélectionnée</h5>
            </b-list-group-item>
            <b-list-group-item class="d-flex align-items-center">
              <div class="circle-legend  mr-2 ml-50" style="background-color: var(--secondary-light)"/>
              <h5>Plante neutre pour la plante sélectionnée</h5>
            </b-list-group-item>
          </b-list-group>

          <JayaTooltip class="font-medium-3" title="Le score d'une plante est calculé comme le nombre d'interactions positives moins le nombre d'interactions négatives, le tout divisé par le nombre des interactions possibles dans le jardin.">
            Comment est calculé le score d'une plante ?
          </JayaTooltip>

          <p class="mt-1 font-italic">*Les données d'interactions - positives ou négatives - entre espèces sont issus
            du travail de recherche de Raphaël Paut, INRA</p>
        </app-collapse-item>
      </app-collapse>
    </b-col>
  </b-row>
</template>

<script type="text/javascript">
import VGardenPlantObject from '@/components/vgarden/VGardenPlantObject.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {BCol, BListGroup, BListGroupItem, BRow} from "bootstrap-vue";

export default {
  name: "vgarden-interactions",
  components: {
    VGardenPlantObject,
    AppCollapse,
    AppCollapseItem,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
  },
  props: {
    vGarden: {
      type: Object,
      default: null
    },
    plantList: {
      type: Array
    },
    removePlants: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      selectedVgardenPlantId: -1,
      interactions: [],
      svgKey: 0,
      pannelSize: null
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.$refs.VGardenInteractions) {
        this.pannelSize = this.$refs.VGardenInteractions.clientWidth
      }
    }, 300);
  },
  computed: {
    radius() {
      return this.pannelSize / 2 - 70
    },
    center() {
      return {x: this.pannelSize / 2, y: this.pannelSize / 2}
    },
    simplify() {
      return (this.plantRadius(this.plantList.length) < 34 ? true : false)
    },
    totalPlants() {
      var vgardenId = Number(this.$route.params.vgardenId)
      if (vgardenId > -1) {
        return this.$store.state.garden.vgardens.find(vgarden => vgarden.id === vgardenId).vgarden_plants.length
      }
      return 0
    }
  },
  methods: {
    plantPosition(index, maxNumber, radius) { //returns the carthesien coordonates of points equally seperated around a circle
      if (this.simplify) {
        radius = radius / 2
      }
      return {
        x: (radius * Math.cos((2 * Math.PI * index) / maxNumber) + this.center.x),
        y: (radius * Math.sin((2 * Math.PI * index) / maxNumber) + this.center.y)
      }
    },
    plantRadius(maxNumber) { //returns the plant radius to make sure they dont overlap
      let result = ((Math.PI * this.radius) / maxNumber - 7)
      if (result > 60) {
        result = 60
      }
      return result
    },
    plantRotation(index, maxNumber) { //returns the angle for the plant
      return ((2 * Math.PI * index) / maxNumber) * 180 / Math.PI
    },
    plantClick(plant) {
      if (!this.removePlants) {
        this.interactions = []
        if (this.selectedVgardenPlantId !== plant.id) {
          this.selectedVgardenPlantId = plant.id
          this.interactions = plant.garden_plant.plant.received_interactions
        } else {
          this.selectedVgardenPlantId = -1
        }
      } else {
        this.$emit('selectedPlant', plant.garden_plant.plant)
      }
    },
    plantColor(plant) {
      if (!this.removePlants) {
        if (this.selectedVgardenPlantId === plant.id) {
          return 'var(--info-light)'
        }
        for (var i = 0; i < this.interactions.length; i++) {
          if (this.interactions[i].plant_giver.id === plant.garden_plant.plant.id) {
            return this.interactions[i].positive ? 'var(--success-light)' : 'var(--danger-light)'
          }
        }
        return 'var(--secondary-light)'
      } else {
        return 'var(--danger-light)'
      }
    },
    textColor(plant) {
      if (this.selectedVgardenPlantId == plant.id) {
        return 'var(--secondary)'
      }
      for (var i = 0; i < this.interactions.length; i++) {
        if (this.interactions[i].plant_giver.id == plant.garden_plant.plant.id) {
          return this.interactions[i].positive ? 'var(--primary-light)' : 'var(--danger-light)'
        }
      }
      return 'var(--dark)'
    },
    interactionPath(selectedVGPlantId, interaction) { // returns the path between two plants in the circle
      let interactingVGPlantId = this.plantList.find(plant => plant.garden_plant.plant.scientific_name == interaction.plant_giver.scientific_name).id
      let p1 //plant 1 point next to it's radius
      let crop1Index = this.plantList.findIndex(plant => plant.id === selectedVGPlantId)
      if (crop1Index >= 0) {
        let plant1Position = this.plantPosition(crop1Index, this.plantList.length, this.radius - this.plantRadius(this.plantList.length))
        p1 = plant1Position.x + ',' + plant1Position.y
      }
      let p2
      let crop2Index = this.plantList.findIndex(plant => plant.id === interactingVGPlantId)
      let p3Position
      let p3
      if (crop1Index === crop2Index) { // When plant has interaction with itself
        let p4Position
        let p4
        p3Position = this.plantPosition(Math.round(crop1Index + this.plantList.length / 4), this.plantList.length, this.radius / 2)
        p4Position = this.plantPosition(Math.round(crop1Index - this.plantList.length / 4), this.plantList.length, this.radius / 2)
        p4 = p4Position.x + ',' + p4Position.y
        p3 = p3Position.x + ',' + p3Position.y
        return 'M' + p1 + ' C' + p3 + ' ' + p4 + ' ' + p1
      } else { //when two plants interact
        if (crop2Index >= 0) {
          let radius = this.simplify ? this.radius - 5 : (this.radius - this.plantRadius(this.plantList.length)) //When simplified, intractionPath goes all the way to center, minus 5px. Otherwise it goes to the edge of the circle
          let plant2Positon = this.plantPosition(crop2Index, this.plantList.length, radius)
          p2 = plant2Positon.x + ',' + plant2Positon.y
        }
        p3Position = this.plantPosition(crop1Index, this.plantList.length, this.radius / 3)
        p3 = p3Position.x + ',' + p3Position.y
        return 'M' + p1 + ' Q' + p3 + ' ' + p2
      }
    },
    ligneStyle(type) {
      if (type) {
        return 'stroke: var(--success)'
      } else {
        return 'stroke: var(--danger); stroke-dasharray:10,2,2,2;'
      }
    },
    plantScore(plant) {
      if (this.totalPlants > 1) {
        var totalPositiveInteractions = 0
        var totalNegativeInteractions = 0
        for (const interactingPlant of plant.garden_plant.plant.received_interactions) {
          if (interactingPlant.positive) {
            totalPositiveInteractions += this.plantList.find(plant => plant.garden_plant.plant.scientific_name == interactingPlant.plant_giver.scientific_name).quantity
          } else {
            totalNegativeInteractions += this.plantList.find(plant => plant.garden_plant.plant.scientific_name == interactingPlant.plant_giver.scientific_name).quantity
          }
        }
        return Math.round(100 * (totalPositiveInteractions - totalNegativeInteractions) / this.totalPlants)
      }
      return null
    },
    getPictoName(plant) {
      return plant.garden_plant.plant.pictos.length > 0 ? plant.garden_plant.plant.pictos[0].name : "default_leaf.svg"
    }
  },
  watch: {
    '$store.state.app.windowWidth'() {
      this.pannelSize = this.$refs.VGardenInteractions.clientWidth
      this.svgKey += 1
    },
    plantList() {
      this.svgKey += 1
    },
    pannelSize(value) {
      if (value > 500) {
        this.pannelSize = 500
      }
      this.svgKey += 1
    }
  }
}
</script>

<style lang="scss">
.interactions-view {
  .circle-legend {
    height: 15px;
    min-width: 15px;
    width: 15px;
    border-color: var(--dark);
    border-style: solid;
    border-width: 1px;
    border-radius: 50%;
    margin-right: 10px;
  }
}
</style>

